<template>
    <div>
        <div class="cajaSuperior">
            <b-row>
                <b-col cols="12" md="3">
                <h2>criterios de <br /> consulta</h2>
                </b-col>

                <!-- Formulario consulta -->
                <b-col>
                    <div class="contenedorForm pl-5">
                        <FormularioConsultaLicencias />
                    </div>
                </b-col>
            </b-row>
        </div>

        <br /> <br />
        <b-row v-if="licenciasServicio.length>0">
            <b-col>
                <b-table 
                    id="consultaLicencias" 
                    striped 
                    responsive 
                    small 
                    bordered 
                    :items="licenciasServicio" 
                    :fields="camposTabla" 
                    :per-page="perPage"
                    :current-page="currentPage"
                    :tbody-tr-class="rowClass" 
                    select-mode="single"
                    ref="selectableTable"
                    selectable
                    @row-selected="onRowSelected">
                    <template #cell(seleccionar)="{ rowSelected }">
                        <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="licenciasServicio.length>0">
            <b-col>
                <b-pagination 
                    v-model="currentPage" 
                    :total-rows="rows" 
                    :per-page="perPage" 
                    aria-controls="consultaLicencias"></b-pagination>
            </b-col>
            <b-col class="text-right">
                <b-button :to="{name: 'agendarVisita'}" :disabled="validarButton">
                    <b-icon icon="plus"></b-icon>
                    Agregar Visita
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import moment from 'moment'
    import FormularioConsultaLicencias from '../components/consultaLicencias/FormularioConsultaLicencias.vue'
    export default {
        name: 'ConsultaLicencias',
        components: {
            FormularioConsultaLicencias
        },
        data(){
            return{
                camposTabla: [
                    'seleccionar',
                    {
                        key: 'num_acto',
                        label: 'Num. Licencia'
                    },
                    'num_visitas', 
                    {
                        key: 'fecha_registro',
                        formatter: (value) => {
                            return moment(value).format("DD-MM-YYYY");
                        },
                    },
                    {
                        key: 'fecha_resolucion',
                        label: 'Fecha Resolución',
                        formatter: (value) => {
                            return moment(value).format("DD-MM-YYYY");
                        },
                    },
                    {
                        key:'cedula_catastral',
                        label: 'Cédula Catastral'
                    },
                    {
                        key:'descripcion_tramite',
                        label: 'Descripción Trámite'
                    },
                    {
                        key:'direccion',
                        label: 'Dirección'
                    },
                    'estrato', 
                    {
                        key:'intervencion',
                        label: 'Intervención'
                    }, 
                    'modalidad', 
                    'titulares', 
                    {
                        key:'urbanizacion',
                        label: 'Urbanización'
                    }, 
                    'uso',
                    'cant_autorizadas', 
                    'cant_dispuestas', 
                ],
                itemSeleccionado: [],
                perPage: 10,
                currentPage: 1,

            }
        },
        computed:{
            ...mapState('consultaLicencias',['licenciasServicio', 'licenciaSeleccionada']),
            rows() {
                return this.licenciasServicio.length
            },
            validarButton(){
                if(this.itemSeleccionado.length>0)return false
                return true
            }
        },
        methods: {
            ...mapActions('consultaLicencias', [ 'asignarLicenciaSeleccionada' ]),
            rowClass(item, type) {
                if (!item || type !== 'row') return
                return 'rowspace'
            },

            onRowSelected(items) {
                this.itemSeleccionado = items
                this.asignarLicenciaSeleccionada(items[0])
            },
        }
    }
</script>