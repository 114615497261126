<template>
    <b-form @submit.prevent="enviarForm">
              <b-form-row>
                <b-col cols="12" md="6">
                    <label  for="fechaInicio">Fecha Inicio:</label>
                    <div class="d-flex align-items-center">
                        <b-form-datepicker 
                            id="fechaInicio" 
                            v-model="form.fecha_inicio" 
                            class="mb-2 mr-2" 
                            placeholder="Elige una fecha" 
                            locale="es"
                            labelNoDateSelected="No hay fecha"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            labelHelp="Use las flechas del teclado para navegar entre fechas"></b-form-datepicker>
                            <b-icon icon="x-circle" variant="danger" @click="borrarFechaInicio" v-if="this.form.fecha_inicio!=null" class="mb-1"></b-icon>
                    </div>
                </b-col>
                
                <b-col cols="12" md="6">
                    <label  for="fechaFin">Fecha Fin:</label>
                    <div class="d-flex align-items-center">
                        <b-form-datepicker 
                            id="fechaFin" 
                            v-model="form.fecha_fin" 
                            class="mb-2 mr-2" 
                            placeholder="Elige una fecha" 
                            locale="es"
                            labelNoDateSelected="No hay fecha"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                            labelHelp="Use las flechas del teclado para navegar entre fechas"></b-form-datepicker>
                            <b-icon icon="x-circle" variant="danger" @click="borrarFechaFin" v-if="this.form.fecha_fin!=null" class="mb-1"></b-icon>
                    </div>
                </b-col>
                
                <b-col cols="12" md="6">
                  <label  for="acta">Licencia</label>
                  <b-form-input
                    id="acta"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="No. Acto"
                    v-model="form.num_acto"
                  ></b-form-input>
                </b-col>
                
                <b-col cols="12" md="6">
                  <label  for="direccion">Dirección</label>
                  <b-form-input
                    id="direccion"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Dirección"
                    v-model="form.direccion"
                  ></b-form-input>
                </b-col>
                
                <b-col cols="12" md="6">
                  <label  for="urbanizacion">Urbanización</label>
                  <b-form-input
                    id="urbanizacion"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Urbanización"
                    v-model="form.urbanizacion"
                  ></b-form-input>
                </b-col>

                <b-col cols="12" md="6"  align-self="end">
                    <b-button class="vertical-align" variant="secondary" type="submit" block>
                        <b-icon icon="search"></b-icon>
                        Consultar
                    </b-button>
                </b-col>

              </b-form-row>
          </b-form>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import {encontrarError} from '../../contantesErrores';

    export default {
        name: 'FormularioConsultaGestionVisita',
        data(){
            return{
                form: {
                    fecha_inicio: null,
                    fecha_fin: null,
                    num_acto: null,
                    direccion: null,
                    urbanizacion: null
                }
            }
        },
        beforeMount(){
            if(this.licenciasServicio.length>0){
                this.form.num_acto = this.licenciaSeleccionada.num_acto
                this.enviarForm()
            }
        },
        computed:{
            ...mapState('consultaLicencias', ['licenciasServicio', 'licenciaSeleccionada'])
        },
        methods:{
            ...mapActions('consultaLicencias', ['buscarLicencias']),

            async enviarForm(){
                try{
                    await this.buscarLicencias(this.form)
                }catch(e){
                    encontrarError(this, e.data)
                }
            },

            borrarFechaInicio(){
                console.log('aqui')
                this.form.fecha_inicio=null
            },

            borrarFechaFin(){
                this.form.fecha_fin=null
            }
        }
    }
</script>

<style scoped>
   
</style>